// src/Portfolio.tsx
import React from 'react';
import Navigation from './sections/Navigation.tsx';
import Hero from './sections/Hero.tsx';
import Skills from './sections/Skills.tsx';
import Projects from './sections/Projects.tsx';
import AboutMe from './sections/AboutMe.tsx';
import Education from './sections/Education.tsx';
import Contact from './sections/Contact.tsx';
import Experience from './sections/Experience.tsx';
import UnderConstructionNotice from './components/UnderConstructionNotice.tsx';
import DynamicHead from './sections/DynaimcHead.tsx';
import Footer from './sections/Footer.tsx';

const Portfolio = () => {
  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <DynamicHead />
      <Navigation />
      <UnderConstructionNotice />
      <Hero />
      <AboutMe />
      <Education />
      <Experience />
      <Projects />
      <Skills />
      <Contact />
      <Footer />
    </div>
  );
};

export default Portfolio;