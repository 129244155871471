// App.js
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout.tsx';
import Portfolio from './Portfolio.tsx';
import Projects from './sections/Projects.tsx';
import { I18nProvider } from './i18n/i18n.context.tsx';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Portfolio />
      },
      {
        path: "projects",
        element: <Projects />
      }
    ]
  }
]);

function App() {
  return (
    <i18nProvider>
      <RouterProvider router={router} />
    </i18nProvider>
  );
}

export default App;