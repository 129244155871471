// /Users/mac/Desktop/software_engineer_homepage/src/sections/Projects.tsx

import React from 'react';
import { motion } from 'framer-motion';
import ProjectCard from '../components/ProjectCard.tsx';
import Tooltip from '../components/tooltip/Tooltip.tsx';
import { useTranslation } from '../i18n/useTranslation.ts';

const Projects = () => {
  const { t } = useTranslation();

  // Get projects data from translations
  const projects = t('projects.items');

  return (
    <div className="relative overflow-display py-6 bg-white dark:bg-gray-900">
      <section className="max-w-6xl mx-auto p-4 sm:p-6 mt-16 sm:mt-24 dark:bg-gray-900" id="works">
        {/* Section Header */}
        <div className="mb-12">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-gray-900 dark:text-gray-100">
            {t('projects.section.title')}
          </h2>
          <p className="text-gray-600 text-lg max-w-2xl dark:text-gray-300">
            {t('projects.section.description')}
          </p>
        </div>
        
        {/* Projects Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {projects.map((project) => (
            <ProjectCard 
              key={project.title} 
              {...project}
              imageUrl={
                // Reuse the existing image URLs
                project.title === "Flop App" 
                  ? "https://images.unsplash.com/photo-1599507593499-a3f7d7d97667?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cHJvZ3JhbW1pbmd8ZW58MHx8MHx8fDI%3D"
                : project.title === "Lendscape"
                  ? "https://images.unsplash.com/photo-1499673610122-01c7122c5dcb?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fHByb2dyYW1taW5nfGVufDB8fDB8fHwy"
                : project.title === "Tribe.so Admin"
                  ? "https://images.unsplash.com/photo-1531030874896-fdef6826f2f7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjJ8fHByb2dyYW1taW5nfGVufDB8fDB8fHwy"
                  : "https://images.unsplash.com/photo-1543966888-cbd9a4456f85?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDd8fHByb2dyYW1taW5nfGVufDB8fDB8fHwy"
              }
              // Add default demo and github URLs
              demoUrl="https://demo.com"
              githubUrl="https://github.com"
            />
          ))}
        </div>

        {/* View More Projects Link */}
        <div className="mt-12 text-center">
          <Tooltip content={t('projects.section.tooltip')} position="bottom">
            <a
              href="https://github.com"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors duration-300 dark:text-gray-300 dark:hover:text-gray-100"
            >
              <span>{t('projects.section.viewMore')}</span>
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </a>
          </Tooltip>
        </div>
      </section>
    </div>
  );
};

export default Projects;