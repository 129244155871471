import React, { useEffect, useState, useCallback } from 'react';
import { motion, useScroll, useTransform, useSpring } from 'framer-motion';
import { User, Mail, Github, Linkedin, Heart, Coffee, Book, Zap } from 'lucide-react';
import { useTranslation } from '../i18n/useTranslation.ts';

const SkillTag = ({ text }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="px-4 py-2 border border-gray-100 dark:border-gray-700
    rounded-xl shadow-sm hover:shadow-md border-sky-100/50 dark:border-sky-800/50 transition-all duration-300 
    hover:border-sky-200/70 dark:hover:border-sky-300/70"
  >
    <div className="flex items-center gap-2">
      <span className="text-sm font-medium bg-gradient-to-r from-gray-700 to-gray-600 
      bg-clip-text text-transparent dark:from-gray-300 dark:to-gray-400">{text}</span>
    </div>
  </motion.div>
);

const InterestBadge = ({ Icon, text }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="flex items-center gap-2 px-4 py-2 bg-gradient-to-br from-sky-50 to-indigo-50 dark:from-gray-700 dark:to-gray-800 
    rounded-xl shadow-sm hover:shadow-md transition-all duration-300"
  >
    <Icon className="w-4 h-4 text-sky-500 dark:text-sky-400" />
    <span className="text-sm text-gray-600 dark:text-gray-300">{text}</span>
  </motion.div>
);

const AboutMe = () => {
  const { t } = useTranslation();
  const { scrollY } = useScroll();
  const [windowWidth, setWindowWidth] = useState(0);

  const smoothImageScale = useSpring(useTransform(scrollY, [0, 300], [1, 0.9]));
  const imageRotate = useTransform(scrollY, [0, 300], [0, 5]);
  const imagePerspective = useTransform(scrollY, [0, 300], [1000, 800]);
  const textY = useTransform(scrollY, [0, 300], [0, 50]);
  const textOpacity = useTransform(scrollY, [0, 300], [0.5, 1]);
  const shadowIntensity = useTransform(scrollY, [0, 300], [20, 0]);
  const smoothShadow = useSpring(shadowIntensity, { stiffness: 100, damping: 30 });

  // Get translated skills and interests
  const skills = t('about.skills');
  const personalInterests = [
    { Icon: Heart, text: t('about.interests.0') },
    { Icon: Coffee, text: t('about.interests.1') },
    { Icon: Book, text: t('about.interests.2') },
    { Icon: Zap, text: t('about.interests.3') }
  ];

  const socialLinks = [
    { Icon: Github, href: "https://github.com/KiwiGaze", color: "text-black dark:text-white" },
    { Icon: Linkedin, href: "https://www.linkedin.com/in/yijiazhenqi/", color: "text-black dark:text-white" },
    { Icon: Mail, href: "mailto:qiyijiazhen@gmail.com", color: "text-black dark:text-white" }
  ];

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="relative overflow-display py-6 bg-white dark:bg-gray-900">
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6" id="about">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-20">
          {/* Left Column - Image & Quick Info */}
          <motion.div className="relative z-10">
            <motion.div 
              className="relative aspect-[4/5] rounded-3xl overflow-hidden bg-white dark:bg-gray-800"
              style={{ 
                scale: smoothImageScale,
                rotateY: imageRotate,
                perspective: imagePerspective,
                boxShadow: useTransform(smoothShadow, s => `0px 4px ${s}px rgba(0, 0, 0, ${s/100})`)
              }}
            >
              <img 
                src='https://images.unsplash.com/photo-1506506200949-df8644f002d1?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                alt="Profile"
                className="w-full h-full object-cover"
              />
              
              <motion.div 
                className="absolute bottom-6 left-6 right-6"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <div className="bg-white/90 dark:bg-gray-700/90 backdrop-blur-md p-4 rounded-2xl shadow-lg">
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 rounded-xl bg-gradient-to-br from-sky-100 to-indigo-100 dark:from-sky-700 dark:to-indigo-700 flex items-center justify-center">
                      <User className="w-5 h-5 text-sky-600 dark:text-sky-400" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900 dark:text-gray-100">{t('about.roles.title')}</h3>
                      <p className="text-sm text-gray-600 dark:text-gray-300">{t('about.roles.experience')}</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </motion.div>

            <motion.div 
              className="absolute -bottom-8 left-1/2 transform -translate-x-1/2 flex gap-4"
              style={{ scale: useTransform(scrollY, [0, 300], [1, 0.9]) }}
            >
              {socialLinks.map((item, index) => (
                <motion.a
                  key={index}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="p-4 transition-all duration-300 group"
                  whileHover={{ y: -5 }}
                >
                  <item.Icon className={`w-5 h-5 ${item.color}`} />
                </motion.a>
              ))}
            </motion.div>
          </motion.div>

          {/* Right Column - Content */}
          <motion.div 
            className="relative z-10 lg:pt-12"
            style={{ 
              y: windowWidth > 1024 ? textY : 0,
              opacity: windowWidth > 1024 ? textOpacity : 1
            }}
          >
            <div className="space-y-12">
              {/* About Section */}
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h1 className="text-3xl sm:text-4xl font-bold bg-gradient-to-br from-gray-900 via-gray-800 to-gray-700 bg-clip-text text-transparent dark:from-gray-100 dark:via-gray-200 dark:to-gray-300 mb-6">
                  {t('about.title')}
                </h1>
                <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                  {t('about.description')}
                </p>
              </motion.div>

              {/* Skills Section */}
              <motion.div 
                className="space-y-6"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  {t('about.sections.expertise')}
                </h2>
                <div className="flex flex-wrap gap-3">
                  {skills.map((skill, index) => (
                    <SkillTag key={index} text={skill} />
                  ))}
                </div>
              </motion.div>

              {/* Interests Section */}
              <motion.div
                className="space-y-6"
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                  {t('about.sections.interests')}
                </h2>
                <div className="flex flex-wrap gap-3">
                  {personalInterests.map((interest, index) => (
                    <InterestBadge key={index} Icon={interest.Icon} text={interest.text} />
                  ))}
                </div>
              </motion.div>

              {/* Enhanced CTA */}
              <motion.div 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <a 
                  href="#contact"
                  className="inline-flex items-center gap-3 px-8 py-4 bg-gradient-to-br from-gray-900 to-gray-800 dark:from-gray-100 dark:to-gray-200 
                  text-white dark:text-gray-900 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 group"
                >
                  <span>{t('about.cta')}</span>
                  <Mail className="w-4 h-4 group-hover:translate-x-1 transition-transform duration-300" />
                </a>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;