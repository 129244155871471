import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const Tooltip = ({ 
  children, 
  content,
  position = 'top',
  delay = 0,
  className = ''
}) => {
  const [isVisible, setIsVisible] = useState(false);

  // Positioning values for different sides
  const positionClasses = {
    top: 'mb-2 bottom-full left-0',
    bottom: 'mt-2 top-full left-0',
    left: 'mr-2 right-full top-0',
    right: 'ml-2 left-full top-0'
  };

  // Arrow positioning for different sides
  const arrowClasses = {
    top: 'bottom-[-4px] left-[50%] -translate-x-1/2',
    bottom: 'top-[-4px] left-[50%] -translate-x-1/2',
    left: 'right-[-4px] top-[50%] -translate-y-1/2',
    right: 'left-[-4px] top-[50%] -translate-y-1/2'
  };

  const handleMouseEnter = () => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, delay);
    return () => clearTimeout(timeoutId);
  };

  return (
    <div 
      className="relative inline-block" 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      
      <AnimatePresence>
        {isVisible && (
          <motion.div
            className={`
              absolute z-50 
              ${positionClasses[position]}
              px-3 py-2 
              min-w-max
              text-sm font-medium 
              text-white dark:text-gray-100 
              bg-gray-900 dark:bg-gray-800 
              rounded-lg shadow-lg
              ${className}
            `}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 5 }}
            transition={{ duration: 0.1 }}
          >
            <div 
              className={`
                absolute w-2 h-2 
                bg-gray-900 dark:bg-gray-800 
                transform rotate-45
                ${arrowClasses[position]}
              `}
            />
            <span className="relative z-10">{content}</span>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Tooltip;