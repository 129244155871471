// src/i18n/i18n.context.tsx
import React, { createContext, useState, useEffect, useCallback } from 'react';
import enTranslations from './locales/en.json';
import zhCNTranslations from './locales/zh-CN.json';
import zhTWTranslations from './locales/zh-TW.json';

type Language = 'en' | 'zh-CN' | 'zh-TW';

interface I18nContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  translations: any;
}

export const I18nContext = createContext<I18nContextType>({
  language: 'en',
  setLanguage: () => {},
  translations: enTranslations,
});

const LANGUAGE_KEY = 'preferred_language';

const translations = {
  'en': enTranslations,
  'zh-CN': zhCNTranslations,
  'zh-TW': zhTWTranslations,
};

export const I18nProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguageState] = useState<Language>('en');

  useEffect(() => {
    // Get browser language
    const browserLang = navigator.language;
    // Get stored language preference
    const storedLang = localStorage.getItem(LANGUAGE_KEY) as Language;
    
    if (storedLang && translations[storedLang]) {
      setLanguageState(storedLang);
    } else if (browserLang.startsWith('zh')) {
      // Handle Chinese variations
      if (browserLang === 'zh-TW' || browserLang === 'zh-HK') {
        setLanguageState('zh-TW');
      } else {
        setLanguageState('zh-CN');
      }
    } else if (translations[browserLang]) {
      setLanguageState(browserLang as Language);
    }
  }, []);

  const setLanguage = useCallback((lang: Language) => {
    setLanguageState(lang);
    localStorage.setItem(LANGUAGE_KEY, lang);
    // Optional: Update HTML lang attribute
    document.documentElement.lang = lang;
  }, []);

  return (
    <I18nContext.Provider value={{
      language,
      setLanguage,
      translations: translations[language],
    }}>
      {children}
    </I18nContext.Provider>
  );
};