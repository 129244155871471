import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const DynamicHead = () => {
  const [pageTitle, setPageTitle] = useState('Portfolio | Home');
  const location = useLocation();

  // Update title based on scroll position
  useEffect(() => {
    const sections = ['home', 'about', 'education', 'experience', 'works', 'skills', 'contact'];
    let lastSection = 'home';

    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100;

      for (const section of sections) {
        const element = document.getElementById(section);
        if (element && scrollPosition >= element.offsetTop) {
          lastSection = section;
        }
      }

      // Format the section name and update title
      const formattedSection = lastSection.charAt(0).toUpperCase() + lastSection.slice(1);
      setPageTitle(`Portfolio | ${formattedSection}`);
      
      // Update favicon color based on scroll position
      const progress = (scrollPosition / (document.body.scrollHeight - window.innerHeight)) * 100;
      const hue = Math.floor((progress / 100) * 200); // Gradient from blue to purple
      const faviconElement = document.getElementById('dynamic-favicon');
      if (faviconElement) {
        faviconElement.setAttribute('href', 
          `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><rect width="100" height="100" rx="20" fill="hsl(${hue}, 70%, 60%)" /><text x="50" y="50" font-size="70" text-anchor="middle" dominant-baseline="middle" fill="white">P</text></svg>`
        );
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial call

    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]);

  // Update document head
  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <>
      <link
        id="dynamic-favicon"
        rel="icon"
        type="image/svg+xml"
        href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><rect width='100' height='100' rx='20' fill='%234F46E5'/><text x='50' y='50' font-size='70' text-anchor='middle' dominant-baseline='middle' fill='white'>P</text></svg>"
      />
    </>
  );
};

export default DynamicHead;