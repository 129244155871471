// src/sections/Skills.tsx
import React from 'react';
import { Code, Terminal, Database, Server } from 'lucide-react';
import { motion } from 'framer-motion';
import { SkillCard } from '../components/ui/index.tsx';
import { useTranslation } from '../i18n/useTranslation.ts';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3
    }
  }
};

const item = {
  hidden: { 
    y: 20, 
    opacity: 0 
  },
  show: { 
    y: 0, 
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

const skillIcons = {
  frontend: Code,
  backend: Terminal,
  database: Database,
  devops: Server
};

const gradients = {
  frontend: "from-blue-100/90 via-indigo-100/90 to-blue-100/90",
  backend: "from-emerald-100/90 via-teal-100/90 to-emerald-100/90",
  database: "from-violet-100/90 via-purple-100/90 to-violet-100/90",
  devops: "from-rose-100/90 via-pink-100/90 to-rose-100/90"
};

const Skills = () => {
  const { t } = useTranslation();

  const skillTypes = ['frontend', 'backend', 'database', 'devops'];

  return (
    <div className="relative overflow-hidden py-6 bg-white dark:bg-gray-900" id="skills">
      <div 
        className="max-w-6xl mx-auto p-4 sm:p-6 mt-16 sm:mt-24 
                   bg-white dark:bg-gray-900 transition-colors duration-500"
      >
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8 
                     bg-gradient-to-br from-gray-800 to-gray-900 
                     dark:from-gray-100 dark:to-gray-300 
                     bg-clip-text text-transparent transition-colors duration-500"
        >
          {t('skills.title')}
        </motion.h2>
        
        <motion.div 
          variants={container}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6"
        >
          {skillTypes.map((skillType) => {
            const Icon = skillIcons[skillType];
            return (
              <motion.div key={skillType} variants={item}>
                <SkillCard 
                  Icon={Icon}
                  title={t(`skills.cards.${skillType}.title`)}
                  description={t(`skills.cards.${skillType}.description`)}
                  className={`
                    bg-gradient-to-br ${gradients[skillType]}
                    dark:bg-gradient-to-br dark:from-gray-800 dark:via-gray-700 dark:to-gray-800
                    rounded-3xl shadow-sm 
                    dark:shadow-gray-700 
                    transition-all duration-500 ease-out
                    hover:shadow-lg hover:scale-[1.02]
                    hover:shadow-gray-200/50 dark:hover:shadow-gray-600/50
                    transform perspective-1000
                    hover:-translate-y-1
                  `}
                />
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};

export default Skills;