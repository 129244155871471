// src/components/ui/index.tsx
import React from 'react';
import { LucideIcon } from 'lucide-react';

// GradientCard component
export const GradientCard = ({ 
  children, 
  className = "" 
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={`relative p-8 rounded-3xl backdrop-blur-sm transition-all duration-500 ${className}`}>
    {children}
  </div>
);

// SocialButton component
export const SocialButton = ({ 
  Icon, 
  href = "#" 
}: {
  Icon: LucideIcon;
  href: string;
}) => (
  <a 
    href={href}
    className="p-3 rounded-xl bg-white/80 hover:bg-white hover:scale-110 hover:shadow-xl transition-all duration-300 group relative overflow-hidden"
  >
    <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-teal-50 opacity-0 group-hover:opacity-10 transition-opacity"></div>
    <Icon className="w-5 h-5 text-gray-600 group-hover:text-gray-900" />
  </a>
);

// SkillCard component
export const SkillCard = ({ 
  Icon, 
  title, 
  description,
  className = "" 
}: {
  Icon: LucideIcon;
  title: string;
  description: string;
  className?: string;
}) => (
  <div className={`p-6 backdrop-blur-sm hover:scale-[1.02] transition-all duration-300 ${className}`}>
    <div className="w-12 h-12 rounded-2xl bg-white/90 flex items-center justify-center mb-4 shadow-sm group-hover:shadow transition-all duration-300">
      <Icon className="w-6 h-6 text-gray-700" />
    </div>
    <h3 className="text-xl font-semibold text-gray-800 mb-3">{title}</h3>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </div>
);