// src/sections/Navigation.tsx

import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AIAssistantLogo from '../components/AIAssistantLogo.tsx';
import { useTranslation } from '../i18n/useTranslation.ts';

const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // Update navItems to use translations
  const navItems: { label: string; href: string; type: string }[] = [
    { label: t('navigation.home'), href: '#home', type: 'section' },
    { label: t('navigation.about'), href: '#about', type: 'section' },
    { label: t('navigation.education'), href: '#education', type: 'section' },
    { label: t('navigation.experience'), href: '#experience', type: 'section' },
    { label: t('navigation.works'), href: '#works', type: 'section' },
    { label: t('navigation.skills'), href: '#skills', type: 'section' },
  ];

  const handleNavigation = (href: string, type: string) => {
    if (type === 'section') {
      const element = document.getElementById(href.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setIsMobileMenuOpen(false);
      }
    } else {
      navigate(href);
      setIsMobileMenuOpen(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const handleScroll = () => {
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(() => {
        const scrolled = window.scrollY > 20;
        setIsScrolled(scrolled);
        
        // Enhanced scroll detection with more generous bounds
        const sections = navItems.map(item => item.href.substring(1));
        const current = sections.find(section => {
          const element = document.getElementById(section);
          if (element) {
            const rect = element.getBoundingClientRect();
            return rect.top <= window.innerHeight / 2 && rect.bottom >= 0;
          }
          return false;
        });

        if (current) setActiveSection(current);
      }, 50);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeout);
    };
  }, [navItems]);

  // Body scroll lock
  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMobileMenuOpen]);

  const menuItemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: (i: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.3,
        ease: 'easeOut'
      }
    }),
    exit: { opacity: 0, x: 20 }
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      <motion.nav
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-500 ${
          isScrolled
            ? 'py-3 bg-white/90 dark:bg-gray-900/90 backdrop-blur-md [-webkit-backdrop-filter:blur(12px)]'
            : 'py-5 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm [-webkit-backdrop-filter:blur(4px)]'
        }`}
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        {/* Removed separate background div and combined backdrop styles with nav */}
        <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
          <div className="flex items-center justify-between">
            {/* Logo */}
            <AIAssistantLogo />

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center gap-1">
              {navItems.map((item) => (
                <motion.div
                  key={item.label as string}
                  className="relative"
                  initial={false}
                >
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {item.type === 'page' ? (
                      <Link
                        to={item.href}
                        className={`relative px-4 py-2 text-sm font-medium transition-colors duration-300 ${
                          activeSection === item.href.substring(1)
                            ? 'text-gray-900 dark:text-white'
                            : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
                        }`}
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <button
                        onClick={() => handleNavigation(item.href, item.type)}
                        className={`relative px-4 py-2 text-sm font-medium transition-colors duration-300 ${
                          activeSection === item.href.substring(1)
                            ? 'text-gray-900 dark:text-white'
                            : 'text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
                        }`}
                      >
                        {item.label}
                      </button>
                    )}
                  </motion.div>
                  <motion.div
                    className="absolute bottom-0 left-0 right-0 h-0.5 bg-gray-900 dark:bg-white"
                    initial={false}
                    animate={{
                      width: activeSection === item.href.substring(1) ? '100%' : '0%',
                      opacity: activeSection === item.href.substring(1) ? 1 : 0
                    }}
                    transition={{ duration: 0.3 }}
                  />
                </motion.div>
              ))}

              <motion.button
                onClick={() => handleNavigation('#contact', 'section')}
                className="ml-4 px-5 py-2 bg-gradient-to-r from-gray-900 to-gray-800 dark:from-gray-700 dark:to-gray-600 text-white text-sm font-medium rounded-xl shadow-sm hover:shadow-md dark:hover:shadow-lg transition-all duration-300 relative overflow-hidden group"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span className="relative z-10">{t('navigation.contact')}</span>
                <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </motion.button>
            </div>

            {/* Mobile Menu Button */}
            <motion.button
              className="md:hidden relative z-[60] p-2.5 rounded-xl bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm [-webkit-backdrop-filter:blur(4px)] border border-gray-100 dark:border-gray-700 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-all duration-300 hover:border-gray-200 dark:hover:border-gray-600"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              aria-label="Toggle menu"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <AnimatePresence mode="wait">
                <motion.div
                  key={isMobileMenuOpen ? 'close' : 'menu'}
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.8, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  {isMobileMenuOpen ? <X /> : <Menu />}
                </motion.div>
              </AnimatePresence>
            </motion.button>
          </div>
        </div>
      </motion.nav>

      {/* Fullscreen Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <>
            {/* Clickable Backdrop */}
            <motion.div
              className="fixed inset-0 z-50 md:hidden bg-black/5 dark:bg-black/20 backdrop-blur-sm [-webkit-backdrop-filter:blur(4px)]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={closeMobileMenu}
            />

            {/* Menu Panel */}
            <motion.div
              className="fixed inset-0 z-[55] md:hidden"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              {/* Menu Background with Safari Support */}
              <div
                className="absolute inset-0 bg-white/95 dark:bg-gray-900/95 backdrop-blur-md [-webkit-backdrop-filter:blur(12px)]"
              />

              {/* Enhanced Close Button */}
              <motion.button
                onClick={closeMobileMenu}
                className="absolute top-4 right-4 p-3 rounded-xl bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-all duration-300 group z-20"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                aria-label="Close menu"
              >
                <div className="relative">
                  <X className="w-6 h-6" />
                </div>
              </motion.button>

              {/* Menu Content */}
              <div className="relative h-full pt-32 pb-8 px-4 flex flex-col">
                <div className="flex-1 flex flex-col justify-center space-y-3 max-w-lg mx-auto w-full">
                  {navItems.map((item, i) => (
                    <motion.div
                      key={item.label}
                      custom={i}
                      variants={menuItemVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      {item.type === 'page' ? (
                        <Link
                          to={item.href}
                          onClick={() => setIsMobileMenuOpen(false)}
                          className={`flex items-center justify-between p-4 rounded-xl transition-all duration-300 relative ${
                            activeSection === item.href.substring(1)
                              ? 'bg-gradient-to-r from-blue-50 to-sky-50 dark:from-blue-900/50 dark:to-sky-900/50 text-blue-900 dark:text-sky-100 shadow-sm dark:shadow-gray-900/30'
                              : 'hover:bg-gray-50/80 dark:hover:bg-gray-800/50 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
                          }`}
                        >
                          <span
                            className={`text-lg font-medium ${
                              activeSection === item.href.substring(1)
                                ? 'text-blue-900 dark:text-sky-100'
                                : 'text-gray-700 dark:text-gray-300'
                            }`}
                          >
                            {item.label}
                          </span>
                          <div className="flex items-center space-x-2">
                            {activeSection === item.href.substring(1) && (
                              <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                className="w-2 h-2 rounded-full bg-blue-500 dark:bg-sky-400"
                              />
                            )}
                            <ChevronRight
                              className={`w-5 h-5 transition-all duration-300 ${
                                activeSection === item.href.substring(1)
                                  ? 'text-blue-500 dark:text-sky-400 translate-x-1'
                                  : 'text-gray-400 dark:text-gray-500'
                              }`}
                            />
                          </div>
                        </Link>
                      ) : (
                        <button
                          onClick={() => handleNavigation(item.href, item.type)}
                          className={`flex items-center justify-between w-full p-4 rounded-xl transition-all duration-300 relative ${
                            activeSection === item.href.substring(1)
                              ? 'bg-gradient-to-r from-blue-50 to-sky-50 dark:from-blue-900/50 dark:to-sky-900/50 text-blue-900 dark:text-sky-100 shadow-sm dark:shadow-gray-900/30'
                              : 'hover:bg-gray-50/80 dark:hover:bg-gray-800/50 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white'
                          }`}
                        >
                          <span
                            className={`text-lg font-medium ${
                              activeSection === item.href.substring(1)
                                ? 'text-blue-900 dark:text-sky-100'
                                : 'text-gray-700 dark:text-gray-300'
                            }`}
                          >
                            {item.label}
                          </span>
                          <div className="flex items-center space-x-2">
                            {activeSection === item.href.substring(1) && (
                              <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                className="w-2 h-2 rounded-full bg-blue-500 dark:bg-sky-400"
                              />
                            )}
                            <ChevronRight
                              className={`w-5 h-5 transition-all duration-300 ${
                                activeSection === item.href.substring(1)
                                  ? 'text-blue-500 dark:text-sky-400 translate-x-1'
                                  : 'text-gray-400 dark:text-gray-500'
                              }`}
                            />
                          </div>
                        </button>
                      )}
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navigation;