import React from 'react';
import { ExternalLink, Github } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTranslation } from '../i18n/useTranslation.ts';

interface ProjectProps {
  title: string;
  description: string;
  gradient: string;
  imageUrl: string;
  demoUrl?: string;
  githubUrl?: string;
  technologies?: string[];
}

const ProjectCard = ({ 
  title, 
  description, 
  imageUrl, 
  demoUrl, 
  githubUrl, 
  technologies = [] 
}: ProjectProps) => {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="rounded-2xl overflow-display transition-shadow duration-300"
    >
      {/* Image Section */}
      <motion.div 
        className="relative w-full aspect-[16/9] overflow-hidden"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      >
        <img
          src={imageUrl}
          alt={`${t('projectCard.imageAlt.prefix')} ${title}`}
          className="rounded-2xl w-full h-full object-cover dark:opacity-90"
        />
      </motion.div>

      {/* Content Section */}
      <div className="p-6">
        {/* Title & Description */}
        <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100 mb-3">{title}</h3>
        <p className="text-gray-600 dark:text-gray-300 mb-6">{description}</p>

        {/* Technologies */}
        {technologies.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-6" 
               role="group" 
               aria-label={t('projectCard.technologies.aria')}>
            {technologies.map((tech) => (
              <span 
                key={tech}
                className="px-3 py-1 bg-gray-100 dark:bg-gray-700 rounded-full text-sm text-gray-600 dark:text-gray-300"
              >
                {tech}
              </span>
            ))}
          </div>
        )}

        {/* Action Buttons */}
        <div className="flex items-center gap-3">
          {demoUrl && (
            <motion.a
              href={demoUrl}
              className="flex items-center gap-2 px-4 py-2 bg-gray-900 dark:bg-gray-700 text-white dark:text-gray-200 rounded-xl hover:bg-gray-800 dark:hover:bg-gray-600 transition-colors duration-300"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={t('projectCard.actions.demoAria')}
            >
              <ExternalLink className="w-4 h-4" />
              <span className="font-medium">{t('projectCard.actions.liveDemo')}</span>
            </motion.a>
          )}
          {githubUrl && (
            <motion.a
              href={githubUrl}
              className="p-2 border border-gray-200 dark:border-gray-700 rounded-xl hover:border-gray-300 dark:hover:border-gray-600 transition-colors duration-300"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={t('projectCard.actions.githubAria')}
            >
              <Github className="w-5 h-5 text-gray-700 dark:text-gray-300" />
            </motion.a>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectCard;