import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ChevronRight } from 'lucide-react';
import { useTranslation } from '../i18n/useTranslation.ts';

interface Message {
  text: string;
  actions?: string[];
  breadcrumbs?: string[];
  isLanguageSelector?: boolean;
}

interface Prompt {
  text: string;
  category: string;
}

interface MessageState {
  isVisible: boolean;
  content: Message | null;
  timeoutId: NodeJS.Timeout | null;
}

const languages = [
  { code: 'en', label: 'English' },
  { code: 'zh-CN', label: '简体中文' },
  { code: 'zh-TW', label: '繁體中文' },
];

const AIAssistantLogo = () => {
  const bubbleRef = useRef<HTMLDivElement>(null);
  const { t, i18n, language, setLanguage } = useTranslation();
  const [messageState, setMessageState] = useState<MessageState>({
    isVisible: false,
    content: null,
    timeoutId: null
  });

  const [currentPrompt, setCurrentPrompt] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [conversationHistory, setConversationHistory] = useState<string[]>([]);
  const [isTyping, setIsTyping] = useState(false);

  // Get prompts from translations
  const prompts = t<Prompt[]>('aiAssistant.prompts');

  // Enhanced messages with translations
  const messages = {
    welcome: {
      text: t('aiAssistant.messages.welcome.text'),
      actions: t('aiAssistant.messages.welcome.actions')
    },
    projects: {
      text: t('aiAssistant.messages.projects.text'),
      actions: t('aiAssistant.messages.projects.actions')
    },
    journey: {
      text: t('aiAssistant.messages.journey.text'),
      actions: t('aiAssistant.messages.journey.actions')
    },
    contact: {
      text: t('aiAssistant.messages.contact.text'),
      actions: t('aiAssistant.messages.contact.actions')
    },
    language: {
      text: t('aiAssistant.messages.language.text'),
      actions: languages.map(lang => lang.label),
      isLanguageSelector: true
    },
    languageConfirm: {
      text: t('aiAssistant.messages.language.confirmationText'),
      actions: [t('aiAssistant.messages.language.actions.0')] // "Go Back"
    }
  };

  const clearMessageTimeout = useCallback(() => {
    if (messageState.timeoutId) {
      clearTimeout(messageState.timeoutId);
    }
  }, [messageState.timeoutId]);

  const showMessage = useCallback((messageContent) => {
    clearMessageTimeout();
    setMessageState({
      isVisible: true,
      content: messageContent,
      timeoutId: setTimeout(() => {
        setMessageState(prev => ({ ...prev, isVisible: false }));
      }, 5000)
    });
  }, [clearMessageTimeout]);

  // Define the actionMap with handler functions
  const actionMap: { [key: string]: () => void } = {
    [String(t('aiAssistant.messages.welcome.actions.0'))]: () => showMessage(messages.projects),
    [String(t('aiAssistant.messages.welcome.actions.1'))]: () => showMessage(messages.journey),
    [String(t('aiAssistant.messages.welcome.actions.2'))]: () => showMessage(messages.contact),
    [String(t('aiAssistant.messages.welcome.actions.3'))]: () => showMessage(messages.language),
    'English': () => {
      setLanguage('en');
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'en');
      showMessage(messages.languageConfirm);
    },
    '简体中文': () => {
      setLanguage('zh-CN');
      i18n.changeLanguage('zh-CN');
      localStorage.setItem('language', 'zh-CN');
      showMessage(messages.languageConfirm);
    },
    '繁體中文': () => {
      setLanguage('zh-TW');
      i18n.changeLanguage('zh-TW');
      localStorage.setItem('language', 'zh-TW');
      showMessage(messages.languageConfirm);
    },
    [String(t('aiAssistant.messages.language.actions.0'))]: () => showMessage(messages.welcome) // "Go Back"
  };

  // Update the handleAction function to use the actionMap
  const handleAction = async (action: string) => {
    setConversationHistory(prev => [...prev, action]);

    const handler = actionMap[action];

    if (handler) {
      handler();
    } else {
      try {
        const sectionId = action.toLowerCase().replace(/\s+/g, '-');
        const element = document.getElementById(sectionId);
        if (element) {
          await element.scrollIntoView({ behavior: 'smooth' });
          setMessageState(prev => ({ ...prev, isVisible: false }));
        } else {
          throw new Error('Section not found');
        }
      } catch (error) {
        showMessage({
          text: "Sorry, I couldn't process that action. Please try again.",
          actions: [t('aiAssistant.messages.language.actions.0')] // "Go Back"
        });
      }
    }
  };

  // Enhanced prompt rotation with typing effect
  useEffect(() => {
    const rotatePrompt = async () => {
      setIsTyping(true);
      await new Promise(resolve => setTimeout(resolve, 300));
      setCurrentPrompt(prev => (prev + 1) % prompts.length);
      await new Promise(resolve => setTimeout(resolve, 300));
      setIsTyping(false);
    };

    const intervalId = setInterval(rotatePrompt, 4000);
    return () => clearInterval(intervalId);
  }, [prompts.length]);

  // Add language persistence
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') as 'en' | 'zh-CN' | 'zh-TW' | null;
    if (savedLanguage) {
      setLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  const handleLanguageIconClick = () => {
    showMessage(messages.language);
  };

  // 3. Add back navigation support
  const handleBack = () => {
    const newHistory = [...conversationHistory];
    newHistory.pop();
    setConversationHistory(newHistory);
    const previousMessage = newHistory[newHistory.length - 1];
    if (previousMessage) {
      handleAction(previousMessage);
    } else {
      showMessage(messages.welcome);
    }
  };

  // 4. Add persistence for conversation state
  useEffect(() => {
    const savedHistory = localStorage.getItem('conversationHistory');
    if (savedHistory) {
      setConversationHistory(JSON.parse(savedHistory));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('conversationHistory', JSON.stringify(conversationHistory));
  }, [conversationHistory]);

  // Add language persistence
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') as 'en' | 'zh-CN' | 'zh-TW' | null;
    if (savedLanguage) {
      setLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  // Add this useEffect for outside click handling
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        bubbleRef.current && 
        !bubbleRef.current.contains(event.target as Node) &&
        messageState.isVisible
      ) {
        setMessageState(prev => ({ ...prev, isVisible: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [messageState.isVisible]);

  return (
    <div className="relative" ref={bubbleRef}>
      <motion.div 
        className="relative z-10 flex items-center gap-3 group cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => showMessage(messages.welcome)}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        {/* Enhanced circle design */}
        <div className="relative">
          {/* Outer glow animation */}
          <motion.div
            className="absolute inset-0 rounded-full bg-gradient-to-r from-sky-200 via-blue-200 to-indigo-200"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.3, 0.1, 0.3],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
          
          {/* Main circle with gradient */}
          <div className="relative w-10 h-10">
            <motion.div 
              className="w-full h-full rounded-full bg-gradient-to-r from-sky-200 via-blue-200 to-indigo-200"
              animate={{
                rotate: isHovered ? 180 : 0,
                scale: isHovered ? 1.1 : 1,
              }}
              transition={{
                duration: 0.6,
                ease: "easeOut"
              }}
            >
              {/* Inner subtle pattern */}
              <div className="absolute inset-0 rounded-full bg-gradient-to-br from-white/40 to-transparent" />
            </motion.div>
          </div>
        </div>
        
        {/* Enhanced text display */}
        <motion.span 
          className="font-medium text-gray-700 dark:text-gray-300 relative overflow-hidden"
          animate={{
            opacity: isTyping ? 0.5 : 1,
            y: isTyping ? 10 : 0,
          }}
          transition={{ 
            duration: 0.3,
            ease: "easeInOut"
          }}
        >
          {prompts[currentPrompt].text}
        </motion.span>
      </motion.div>

      {/* Enhanced popup message */}
      <AnimatePresence>
        {messageState.isVisible && messageState.content && (
          <motion.div
            initial={{ opacity: 0, y: 10, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -10, scale: 0.95 }}
            className="absolute left-0 top-14 w-72 bg-white dark:bg-gray-800 rounded-2xl shadow-xl border border-gray-100 dark:border-gray-700"
            style={{
                backdropFilter: 'blur(12px)',
                WebkitBackdropFilter: 'blur(12px)',
            }}
            >
            {/* Message header */}
            <div className="p-4 border-b border-gray-100/80 dark:border-gray-700/80">
              <div className="flex justify-between items-start">
                <div className="flex items-center gap-2">
                  <motion.div 
                    className="w-2 h-2 rounded-full bg-green-400"
                    animate={{
                      scale: [1, 1.2, 1],
                      opacity: [1, 0.7, 1],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Portfolio Guide</span>
                </div>
                <button
                  aria-label="Close message"
                  onClick={() => setMessageState(prev => ({ ...prev, isVisible: false }))}
                  className="p-1.5 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-all duration-300"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            </div>

            {/* Message content */}
            <div className="p-4">
              <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">{messageState.content.text}</p>
              
              <div className="space-y-2">
                {messageState.content.actions?.map((action, index) => (
                  <motion.button
                    key={action}
                    onClick={() => handleAction(action)}
                    className="w-full px-4 py-2.5 text-sm text-left text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100 rounded-xl hover:bg-gray-50/80 dark:hover:bg-gray-700/80 transition-all duration-300 flex items-center justify-between group"
                    whileHover={{ x: 4 }}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    {action}
                    <ChevronRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-all duration-300" />
                  </motion.button>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AIAssistantLogo;