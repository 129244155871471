// src/i18n/useTranslation.ts
import { useContext } from 'react';
import { I18nContext } from './i18n.context.tsx';

interface TranslationOptions {
  returnObjects?: boolean;
}

export const useTranslation = () => {
  const { language, setLanguage, translations } = useContext(I18nContext);

  const t = <T>(key: string, options?: TranslationOptions): T => {
    const keys = key.split('.');
    let value = translations;
    
    for (const k of keys) {
      if (value?.[k] === undefined) {
        console.warn(`Translation missing for key: ${key}`);
        return key as unknown as T;
      }
      value = value[k];
    }
    
    if (options?.returnObjects && Array.isArray(value)) {
      return value as T;
    }
    
    return value as T;
  };

  return {
    t,
    language,
    setLanguage,
    i18n: {
      changeLanguage: setLanguage,
      language
    }
  };
};